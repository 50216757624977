import React from 'react';
import styled from 'styled-components';
import {
  textStyle,
  textAlign,
  space,
  color,
  layout,
  typography,
  LayoutProps,
  SpaceProps,
  TextStyleProps,
  TextAlignProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';

import { EmphasedText } from '@/types';

interface TextProps
  extends TextStyleProps,
    LayoutProps,
    TextAlignProps,
    SpaceProps,
    ColorProps,
    TypographyProps {}

const Text = styled.span<TextProps>`
  ${textStyle}
  ${textAlign}
  ${space}
  ${layout}
  ${color}
  ${typography}
`;

export const makeEmphasedText = (textSlices: EmphasedText[]): JSX.Element[] =>
  textSlices.map(({ text, emphasis, id }, index) => (
    <Text key={id} color={emphasis ? 'primary' : undefined}>
      {`${text}${index === textSlices.length - 1 ? '' : ' '}`}
    </Text>
  ));
export default Text;
