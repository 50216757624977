import React from 'react';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import styled, { css } from 'styled-components';

import { Container } from '@/components/Layout/Container';
import Text from '@/components/Text/Text';
import CTA from '@/components/CTA/CTA';
import { ScrollSpy } from '@/components/ScrollSpy/ScrollSpy';

type NavBarWrapperProps = {
  showMenu?: boolean;
};

const NavBarWrapper = styled.header<NavBarWrapperProps>`
  display: flex;

  .vspace {
    height: 44px;
    visibility: hidden;
  }

  ${(props) =>
    props.showMenu
      ? css`
          background-color: #243b55;
          .logo {
            color: ${props.theme.colors.white};
          }
        `
      : css`
          background-color: ${props.theme.colors.white};
          .logo {
            color: ${props.theme.colors.primaryText};
          }
        `}
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  /* Menu */
  ul {
    li {
      a {
        color: ${(props) => props.theme.colors.white};
        transition: all 0.3s ease;
        &:hover {
          color: ${(props) => props.theme.colors.primary};
        }
      }
      &.is-current {
        a {
          color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }

  @media (max-width: 992px) {
    .ctaButton {
      padding: 0 15px;
      height: 36px;
    }

    .vspace {
      height: 40px;
    }
  }
`;

const NavBarInner = styled.nav`
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  transition: all 0.3s ease;
`;

const MenuList = styled.ul`
  margin: 0 2rem;
  display: flex;

  @media (max-width: 992px) {
    display: none;
  }
`;

const MenuItem = styled.li`
  padding: 0 ${(props) => props.theme.space[5]};
`;

type NavMenuItem = {
  id: number;
  label: string;
  path: string;
};

type NavbarContent = {
  landingContentJson: {
    navbar: { navMenu: NavMenuItem[] };
  };
};

export const query = graphql`
  {
    landingContentJson {
      navbar {
        navMenu {
          id
          label
          path
        }
      }
    }
  }
`;

const Navbar: React.FC = () => {
  const {
    landingContentJson: {
      navbar: { navMenu },
    },
  } = useStaticQuery<NavbarContent>(query);

  const { pathname } = useLocation();

  const showMenu = pathname === '/';

  const handleScroll: React.MouseEventHandler = (event) => {
    event.preventDefault();
    const { hash } = event.currentTarget as HTMLAnchorElement;

    window.history.pushState(null, null, hash);

    const targetSection = document.querySelector(hash);

    if (targetSection !== null) {
      const { top: relativeTopPosition } =
        targetSection.getBoundingClientRect();
      const offsetPosition = relativeTopPosition + window.scrollY;

      window.scrollTo({
        top: offsetPosition - 75,
        behavior: 'smooth',
      });
    }
  };

  return (
    <NavBarWrapper className="navbar" showMenu={showMenu}>
      <ScrollSpy activeClassName="is-current" />
      <Container style={{ width: '100%' }}>
        <NavBarInner className="navbar__menu">
          <Text
            as="h2"
            textStyle="secondaryHeading"
            marginRight="auto"
            className="logo"
          >
            <Link to="/">
              <Text>Palestra</Text>
            </Link>
          </Text>
          {showMenu && (
            <>
              <MenuList>
                {navMenu.map(({ id, label, path }) => (
                  <MenuItem key={id} data-spy-id={path}>
                    <a href={`#${path}`} onClick={handleScroll}>
                      <Text textStyle="tertiaryHeading" className="menuText">
                        {label}
                      </Text>
                    </a>
                  </MenuItem>
                ))}
              </MenuList>
              <CTA
                className="ctaButton"
                message="devenir créateur"
                onClick={() => {
                  navigate('/contact');
                }}
              />
            </>
          )}
          <div className="vspace" />
        </NavBarInner>
      </Container>
    </NavBarWrapper>
  );
};

export default Navbar;
